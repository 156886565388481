<template>
  <v-list-item v-if="item">
    <v-row no-gutters class="align-center">
      <v-col cols="12" md="5">
        <div class="d-flex flex-column">
          <ProductPromo
            :warehousePromo="item.product.warehousePromo"
            v-if="item.product.warehousePromo"
          ></ProductPromo>
          <div class="d-flex align-center product-column">
            <v-img
              :src="item.product.mediaURL"
              class="product-img d-xs-none"
              width="85px"
              height="85px"
              max-height="85px"
              max-width="85px"
            />
            <div class="product-info">
              <span class="product-code">Cod. {{ productCode }}</span>
              <span class="product-name">{{ item.product.name }}</span>
              <span class="product-brand">{{ item.product.shortDescr }}</span>
              <div class="product-descr">{{ item.product.description }}</div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="4" md="2" class="justify-center justify-sm-left">
        <ProductPrice
          :product="item.product"
          :showStandard="false"
        ></ProductPrice
      ></v-col>

      <v-col cols="7" md="4" class="d-flex justify-center">
        <template v-if="isActive">
          <div
            class="qty-wrap rounded-pill"
            :class="{ 'not-empty': quantity > 0 }"
            transition="fab-transition"
          >
            <a
              role="button"
              class="minus "
              v-on:click.prevent.stop="minus"
              v-ripple
            >
              <v-icon>$minus</v-icon>
            </a>
            <div class="val-cnt">
              <span class="val">{{ quantity }} {{ unit }}</span>
              <span class="small">{{ quantityPerUnit }}</span>
            </div>
            <a role="button" class="plus " v-on:click.prevent="plus" v-ripple>
              <v-icon v-if="quantity > 0">$plus</v-icon>
            </a>
          </div>
          <a
            role="button"
            class="add-to-cart "
            v-on:click.prevent="
              () => {
                if (item.product.price || item.product.priceDisplay) {
                  addToCart();
                }
              }
            "
            :class="{
              disabled: !item.product.price && !item.product.priceDisplay
            }"
            v-ripple
          >
            <v-icon>$cart</v-icon>
          </a>
        </template>
        <template v-else>
          <span>Prodotto non acquistabile</span>
        </template>
      </v-col>
      <v-col cols="1" md="1" class="d-flex align-center justify-center">
        <v-btn
          small
          icon
          class="ml-1 delete-item-btn"
          v-on:click.prevent="removeItem()"
        >
          <v-icon>$delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-list-item>
</template>

<script>
import ProductPrice from "@/components/product/ProductPrice";
import ProductPromo from "@/components/product/ProductPromo";
import CatalogService from "~/service/catalogService";
import CartService from "~/service/cartService";

import { mapActions } from "vuex";
import { formatProductCode } from "@/service/marketsuiteUtils";
export default {
  name: "ListItem",
  components: { ProductPrice, ProductPromo },
  props: ["item", "isActive"],
  // mixins: [productMixin],

  computed: {
    productCode() {
      return formatProductCode(this.item.product);
    },
    quantity() {
      // let item = this.item;
      if (this.item === null || this.item.product === null) return 0;
      if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
        //Handling floating point decimals issues
        var val = (this.item.weight * 100).toFixed(0);
        val = val * CatalogService.getMultiplier(this.item.product);
        val = val / 100;
        return val;
      } else {
        return this.item.quantity;
      }
    },
    quantityPerUnit() {
      let quantity = this.quantity;
      if (quantity === 0) return;
      if (
        this.item.product.productInfos.TIPOLOGIA === "Sfuso" &&
        this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
      ) {
        try {
          var singleUnit = Math.floor(
            quantity /
              parseFloat(
                this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
              )
          );
          var singleUnitUm = this.item.product.productInfos
            .WEIGHT_SELLING_SINGLE_UNIT_UM
            ? this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT_UM
            : "pz";
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      if (this.item.product.productInfos.TIPOLOGIA === "Confezione") {
        try {
          let singleUnit =
            quantity *
            parseFloat(this.item.product.productInfos.WEIGHT_SELLING);
          singleUnit = (singleUnit * 1000).toFixed(0);
          singleUnit = singleUnit / 1000;
          let singleUnitUm = this.item.product.productInfos.WEIGHT_UNIT_SELLING;
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      return null;
    },
    unit() {
      if (this.item.product.productInfos) {
        if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
          return this.item.product.productInfos.WEIGHT_UNIT_SELLING;
        } else {
          return this.item.product.productInfos.UNIT_SELLING != null
            ? this.item.product.productInfos.UNIT_SELLING
            : "pz";
        }
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapActions({
      addProduct: "cart/addProduct"
    }),
    removeItem() {
      this.$emit("removeItem", {
        itemId: this.item.itemId,
        product: this.item.product
      });
    },
    plus() {
      const newQuantity = CartService.plus(this.item.product, this.quantity);

      this.$emit("update", { newQuantity, item: this.item });
    },
    async minus() {
      const newQuantity = CartService.minus(this.item.product, this.quantity);
      let res = true;
      if (newQuantity <= 0) {
        res = await this.$dialog.confirm({
          text: global.vm.$t("message.removeFromWishlist")
        });
      }
      if (res) this.$emit("update", { newQuantity, item: this.item });
    },
    addToCart() {
      this.addProduct({ product: this.item.product, quantity: this.quantity });
    }
  }
};
</script>
