<template>
  <div class="py-5 text-center">
    <div
      class="text-decoration-line-through body-2 font-weight-bold red--text"
      v-if="product.priceStandardDisplay || product.priceDisplay"
    >
      <div
        class="body-1 font-weight-bold"
        :class="{ 'promo-price': product.priceStandardDisplay }"
        itemprop="price"
      >
        {{ $n(product.priceDisplay, "currency") }}
        <span class="text-h6" v-if="product.productInfos.TIPOLOGIA != 'Pezzo'"
          >/{{ product.weightUnitDisplay }}</span
        >
      </div>
    </div>
    <div>
      <span
        class="text-h6 primary--text font-weight-bold"
        v-if="
          product.productInfos &&
            product.productInfos.TIPOLOGIA == 'Pezzo' &&
            product.priceUmDisplay
        "
      >
        {{ $n(product.priceUmDisplay, "currency") }}/{{
          product.weightUnitDisplay
        }}
        {{ ivaCategory }}
      </span>
    </div>
  </div>
</template>
<style scoped lang="scss">
.promo-price {
  color: var(--v-primary-base);
}
</style>
<script>
export default {
  name: "ProductPrice",
  props: ["product"],
  computed: {
    ivaCategory() {
      return this.product.ivaCategory && this.product.ivaCategory.descr
        ? " - " + this.product.ivaCategory.descr
        : "";
    }
  }
};
</script>
