<template>
  <div class="d-flex" :class="[warehousePromo.view.cssClass]">
    <span v-html="warehousePromo.view.header"></span>
    <span v-html="warehousePromo.view.body"></span>
    <span v-html="warehousePromo.view.footer"></span>
  </div>
</template>
<script>
export default {
  name: "ProductPromo",
  props: { warehousePromo: { type: Object, required: true } }
};
</script>
